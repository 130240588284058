const badge_radio = {
  init() {
    $('.badge_radio').on('click', (e) => {
      $('.badge_radio').removeClass('active')
      $(e.currentTarget).addClass('active')
      $('.badge_radio input[type="radio"]').prop('checked', false)
      $(e.currentTarget).find('input[type="radio"]').prop('checked', true)
    })
  }
}

export default badge_radio