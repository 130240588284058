const image_selector = {

    current_open: null,

    init() {
        $(document).on('click', (e) => {
            if (!$(e.target).closest('.image-container').length && this.current_open) {
                $(this.current_open).removeClass("active")
            }
        })

        $(".image-container").on('click', (e) => {
            if(!$(e.currentTarget).hasClass('active')) {
                $(this.current_open).removeClass("active")
            }
        })

        $(".image_selector .selected").on("click", (e) => {
            $(this.current_open).removeClass("active")
            $(e.currentTarget).parent(".image_selector").toggleClass("active")

            if($(e.currentTarget).parent(".image_selector").hasClass('active')) {
                this.current_open = $(e.currentTarget).parent(".image_selector")[0]
            } else {
                this.current_open = null
            }
        })

        $(".image_selector .image_list .option").on("click", (e) => {
            let id = $(e.currentTarget).attr("data-id")
            $(e.currentTarget).closest(".image-container").attr("data-selected", id)

            $(e.currentTarget).closest(".image_selector").find(".selected .name").text($(e.currentTarget).text())
            $(e.currentTarget).closest(".image_list").find(".option.selected").removeClass("selected")
            $(e.currentTarget).addClass("selected")

            $(this.current_open).removeClass("active")
        })
    }
}

export default image_selector