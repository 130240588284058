import "@hotwired/turbo-rails"
import "../controllers"
import "../turbo_stream_actions"
import "@rails/actiontext"
import "trix"
require("tom-select")
require("@rails/activestorage").start()

import jquery from "jquery";
window.jQuery = jquery;
window.$ = jquery;

if (RAILS_ENV !== "development") {
    console.log = () => {};
    console.debug = () => {};
    console.info = () => {};
    console.warn = () => {};
}

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import captcha from '../shared/javascript/components/captcha'
import nav from "../shared/javascript/components/nav"
import linked from "../shared/javascript/components/linked"
import create_shortcut from "../shared/javascript/components/create_shortcut"
import toggle_list from "../shared/javascript/components/toggle_list"
import v_binder from "../shared/javascript/components/v-binder"
import check_lock from "../shared/javascript/components/check-lock"
import sensible from "../shared/javascript/components/sensible"
import cluster from "../shared/javascript/components/cluster"
import fake_radio from "../shared/javascript/components/fake-radio"
import image_selector from "../shared/javascript/components/image_selector"
import subbar from "../shared/javascript/components/subbar"
import required from "../shared/javascript/components/required"
import showif from "../shared/javascript/components/showif"
import validator from "../shared/javascript/components/validator"
import sidebar from "../shared/javascript/components/sidebar"
import filter from "../shared/javascript/components/filter"
import badge_radio from "../shared/javascript/components/badge-radio"
import popup from "../shared/javascript/components/popup"
import search from "../shared/javascript/components/search"


$(document).ready(() => {
    sidebar.init()
    subbar.init()
})

let captchaInitialized = false;

document.addEventListener("turbo:load", () => {
    nav.init()
    linked.selectors.push("table.linked tbody tr.interactive", ".linked", "nav .interactive")
    linked.init()
    linked.clearSelectors()
    create_shortcut.init();
    toggle_list.init();
    v_binder.init();
    check_lock.init()
    sensible.init()
    cluster.init()
    fake_radio.init()
    image_selector.init()
    subbar.init()
    required.init()
    showif.init()
    validator.init()
    filter.init()
    badge_radio.init()
    popup.setup()
    captcha.init()
})

document.addEventListener("turbo:frame-missing", (event) => {
    console.warn("Turbo Frame missing, redirecting.")
    const { detail: { response, visit } } = event;
    event.preventDefault();
    visit(response); // you have to render your "application" layout for this
});
