import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="subbar"
export default class extends Controller {
  static targets = ["subbar", "context"];
  containers = this.contextTarget.querySelectorAll('.subbar__container');
  tabs = this.subbarTarget.children;

  connect() {

    this.reset()

      this.tabs[0].classList.add("active");
      this.containers[0].classList.add("active");


    for (let i = 0; i < this.tabs.length; i++) {
      this.tabs[i].addEventListener('click', (e)=>{
        if(e.target.classList.contains('subbar__item')){
          this.update(e.target)
        }
        else{
          this.update(e.target.parentElement)
        }
      })
    };
  }

  reset() {
    for (let i = 0; i < this.tabs.length; i++) {
      this.tabs[i].classList.remove("active");
      this.containers[i].classList.remove("active");
    }
  }

  update(target){
    this.reset()
    target.classList.add("active");
    const id = target.children[0].dataset.containerId;
    document.getElementById(`${id}`).classList.add("active");
  }
}
