const cluster = {
    init() {
        $(".cluster .cluster_type_badge .add_ht").on("click", (e) => {
            let id = $(e.currentTarget).attr("data-id")
            $(".cluster form #cluster_cluster_types_to_add_").val(id)
            $(".cluster form").submit();
        })

        $(".cluster .remove_ht").on("click", (e) => {
            let id = $(e.currentTarget).attr("data-id")
            $(".cluster form #cluster_cluster_types_to_remove_").val(id)
            $(".cluster form").submit();
        })
    }
}

export default cluster