import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="range-input"
export default class extends Controller {
  static targets = ["wrap"];
  connect() {

        const range = this.wrapTarget.querySelector("input[type=range]");
        const bubble = this.wrapTarget.querySelector(".bubble");


        this.setBubble(range, bubble);
        this.updateBubblePos(range, bubble);
        
        range.addEventListener("input", () => {
            this.setBubble(range, bubble);
            this.updateBubblePos(range, bubble);
        });
  }

  setBubble(range, bubble){
    bubble.innerText = range.value;
  }

  updateBubblePos(range, bubble){
    const val = range.value;
    const min = range.min ? range.min : 0;
    const max = range.max ? range.max : 100;
    const newVal = Number(((val - min) * 100) / (max - min));

    bubble.style.left = `${newVal}%`;
  }
}
