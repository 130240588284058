const pricing = {
    init() {
        this.popup_setup();
    },

    popup_setup() {
        $("#account_config_reserved").change(function () {
            if (this.checked) {
                $("#account_config_slug").val($("#account_config_slug").val() + "_reserved")
            } else {
                $("#account_config_slug").val($("#account_config_slug").val().replace("_reserved", ""))
            }
        })
    }
};

export default pricing;