const validator = {
  init() {
    $("input[validate]").each((i, input) => {
      let url_validate = $(input).attr('validate-url') == "" ? window.location.href : $(input).attr('validate-url')
      $(input).on("change", () => {
        this.triggerValidation($(input).closest('form'), url_validate)
      })
    })
  },

  triggerValidation(form, url_validate) {
    $.ajax({
      method: 'GET',
      url: url_validate,
      data: this.getFormData(form),
      success: (data) => {
        this.fillErrors(data)
      }
    })
  },

  fillErrors(errors) {
    // cleaning error messages
    $('[validator-receive]').text('')
    $("form input[type='submit']").attr('disabled', false)

    if(errors.length > 0) {
      $("form input[type='submit']").attr('disabled', true)

      errors.forEach(error => {
        let key = Object.keys(error)[0]
        let val = error[key]
        $(`[validator-receive=${key}]`).text(val)
      });
    }
  },

  getFormData($form){
    let unindexed_array = $form.serializeArray();
    let indexed_array = {};

    $.map(unindexed_array, function(n, i){
        let value = n['value'];
        if(value == "") {
          value = undefined
        }
        indexed_array[n['name']] = value;
    });

    return indexed_array;
  }
}

export default validator