const sidebar = {
  init() {
    $(".nav .sidebar .section").each((i, section) => {
      if ($(section).children(".head").length && $(section).children(".link__container").length) {
          $(section).find(".head").on("click", () => {
              if($(section).children(".link__container").hasClass("active")) {
                  $(section).find(".link__container").removeClass("active")
                  $(section).find(".head span.toggle").removeClass("active")
              } else {
                  $(section).find(".link__container").addClass("active")
                  $(section).find(".head span.toggle").addClass("active")
              }
          })
      }
    })

    $(".nav .sidebar .link").on('click', (e) => {
        $(".nav .sidebar .link").removeClass('active')
        $(e.currentTarget).addClass('active')
    })
  }
}

export default sidebar