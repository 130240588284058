const v_binder = {
    init() {
        $("input[v-receiver]").each((i, input) => {
            let input_ids = $(input).attr("v-receiver").split(" ").filter(e => e[0] == "#")

            $(input_ids.join(",")).on("input", () => {
                $(input).val(input_ids.map(id => $(id).val()).join(" "))
            })
        })
    }
}

export default v_binder