import { WidgetInstance } from "friendly-challenge";

const captcha = {
    options: {
        sitekey: "FCMP234SUOJLK4LL",
        language: "fr",
    },

    init(){
        const element = document.querySelector(".frc-captcha__widget");
        if (element) {
            const widget = new WidgetInstance(element, this.options);
            widget.start()
        }
    }

}

export default captcha