import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dropdown"
export default class extends Controller {
  static targets = ["dropdown", "dropdownContent", "dropdownButton"]
  
  connect() {
  }

  dropEvent(){
      this.dropdownTarget.classList.toggle('open');
  }
}
