import pricing from "../../../admin/javascript/pages/pricing"

const popup = {
    init(html, callback=()=>{}, size="reg") {
        let html_popup = `
            <div class="popup ${size}">
                <span class="f-x"></span>
                <div class="popup__content">
                </div>
            </div>
        `

        html_popup = $(html_popup)

        this.close()
        $("body").append('<div class="modal_screen"></div>')
        $("main").append(html_popup)
        html_popup.find(".popup__content").append(html)

        html_popup.find("span.f-x").on("click", () => {
            popup.close()
        })

        pricing.popup_setup()

        callback(html_popup);
    },

    close() {
        $(".popup").remove()
        $(".modal_screen").remove()
    },

    setup() {
        $('.popup .f-x').on('click', () => {
            this.close()
        })
    }
}

export default popup