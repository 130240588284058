import popup from "./popup";

const sensible = {

    s_input: null,
    s_form_class: null,

    init() {
        $('.sensible').on("click", (e) => {
            e.preventDefault()

            let s_with = $(e.currentTarget).attr("sensible-with")
            sensible.s_input = $(e.currentTarget).attr("sensible-input")
            sensible.s_form_class = $(e.currentTarget).attr("sensible-form")

            if($(e.currentTarget).attr("sensible-input-check")) {
                $("#" + $(e.currentTarget).attr("sensible-input-check")).val(true)
            }

            if(s_with && sensible.s_input) {
                if(s_with == "password") {
                    sensible.ask_password()
                }
            }
        })
    },

    ask_password() {
        // consider that there is already a form
        if($('.sensible_container').length) {
            $('.sensible_container').removeClass('hide')
            $('.sensible_container').closest('.popup').removeClass('hide')
            $('.modal_screen').removeClass('hide')
        } else {
            let html_form = `
            <div class="form sensible_container">
                <h3 class="mb-1">Validation</h3>
                <p>Afin de confirmer votre action, merci de renseigner votre mot de passe utilisateur.</p>
                <div class="field force">
                    <label>Mot de passe</label>
                    <input type="password" name="sensible_password" id="sensible_password">
                </div>
                <br>
                <input type="submit" value="Valider" id="sensible_submit" class="bg-red no-shadow mt-2">
            </div>
            `

            popup.init(html_form)

            $("#sensible_password").on("input", () => {
                $("#" + this.s_input).val($("#sensible_password").val())
            })
    
            $("#sensible_submit").on("click", (e) => {
                if(sensible.s_form_class) {
                    $("form." + sensible.s_form_class).submit()
                } else {
                    $('form').submit()
                }
            })
        }
    }
}

export default sensible