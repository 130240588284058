const fake_radio = {
    init() {
        $(".fake-radio input[type='radio']").each((i, e) => {
            if($(e).attr('data-check') == 'true') {
                let input_name = $(e).attr("data-name")
                $(`input[name="${input_name}"]`).parent(".fake-radio").removeClass("active")
                $(e).parent(".fake-radio").addClass("active")
            }
        })
        $(".fake-radio input[type='radio']").on("click", (e) => {
            let input_name = $(e.currentTarget).attr("name")
            $(`input[name="${input_name}"]`).parent(".fake-radio").removeClass("active")
            $(e.currentTarget).parent(".fake-radio").addClass("active")
        })
    }
}

export default fake_radio