import {Controller} from "@hotwired/stimulus";
import TomSelect from "tom-select";

export default class extends Controller {
    static targets = ["select"];

    connect() {
        let settings = {
            maxItems: parseInt(this.selectTarget.dataset.maxItems),
            hidePlaceholder: this.selectTarget.dataset.hidePlaceholder === "true",
            plugins: ["remove_button"]
        };
        new TomSelect(this.selectTarget, settings);
    }

}