const nav = {

    init() {
        $("nav .avatar.interactive").on("click", () => {
            $("nav .user_menu").toggleClass("active")
            $("nav .avatar.interactive").toggleClass("active")
        })
    }
}

export default nav