import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="current-project"
export default class extends Controller {
  initialize() {
  }

  connect() {
  }

  handleChange(event) {
    let baseUrl = window.location.origin;
    let projectId = event.target.value;
    window.location = `${baseUrl}/projects/${projectId}`;
  }
}
