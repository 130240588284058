const create_shortcut = {
    init() {
        $(".nav header .create_shortcut").on("click",
            () => {
                $(".nav header .create_shortcut .create_shortcuts").toggleClass("active")
            }
        )
    }
}

export default create_shortcut