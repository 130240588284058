const panel = {
    init(html, callback=()=>{}) {
        let html_panel = `
            <div class="panel">
                <span class="f-x"></span>
                <div class="panel__content">
                </div>
            </div>
        `

        html_panel = $(html_panel)

        this.close()
        $("main").append(html_panel)
        html_panel.find(".panel__content").append(html)

        html_panel.find("span.f-x").on("click", () => {
            panel.close()
        })

        callback(html_panel);
    },

    close() {
        $(".panel").remove()
    }
}

export default panel