const required = {
    init() {
        $("input[required-with]").each((i, input) => {
            $(`input#${$(input).attr("required-with")}`).on("change", (e) => {
                if($(e.currentTarget).val()) {
                    $(input).attr("required", true)
                } else {
                    $(input).attr("required", false)
                }
            })
        })

        $("input[required-if]").each((i, input) => {
            $(input).on("change", () => {
                if($(`input#${$(input).attr("required-if")}`).val()) {
                    $(input).attr("required", false)
                } else {
                    $(input).attr("required", true)
                }
            })

            $(`input#${$(input).attr("required-if")}`).on("change", (e) => {
                if($(e.currentTarget).val()) {
                    $(input).attr("required", false)
                } else {
                    $(input).attr("required", true)
                }
            })
        })

        $("input[required-ifeq], select[required-ifeq]").each((i, input) => {
            let input_id, value
            [input_id, value] = $(input).attr("required-ifeq").split(",")

            if($(`input#${input_id}, select#${input_id}`).val() != value) {
                $(input).attr("required", false)
            } else {
                $(input).attr("required", true)
            }

            $(input).on("change", () => {
                if($(`input#${input_id}, select#${input_id}`).val() != value) {
                    $(input).attr("required", false)
                } else {
                    $(input).attr("required", true)
                }
            })

            $(`select#${input_id}`).on("change", (e) => {
                if($(e.currentTarget).val() != value) {
                    $(input).attr("required", false)
                } else {
                    $(input).attr("required", true)
                }
            })
        })
    },
}

export default required