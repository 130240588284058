const check_lock = {
    init() {
        $(".check-lock input[type='checkbox']").on("click", (e) => {
            if($(e.currentTarget).is(':checked')) {
                $(e.currentTarget).next("span").removeClass("f-lock-open-sm")
                $(e.currentTarget).next("span").addClass("f-lock-closed-sm")
            } else {
                $(e.currentTarget).next("span").removeClass("f-lock-closed-sm")
                $(e.currentTarget).next("span").addClass("f-lock-open-sm")
            }
        })
    }
}

export default check_lock