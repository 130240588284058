import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="popup"
export default class extends Controller {
  static targets = ["popup", "filter", "field"]
  connect() {
  }

  openPopup() {
    const link = this.element.dataset.popupLink;
    const frame = document.querySelector("#popup");
    const options = this.data.has("popupOptions") ? this.data.get("popupOptions") : {};

    frame.setAttribute('src', link);
  }

  hidePopup() {
    this.element.parentElement.removeAttribute("src")
    this.element.remove()
  }

  submitEnd(event) {
    if (event.detail.success) {
      this.hidePopup()
    }
  }

  closeWithKeyboard(event) {
    if (event.code == "Escape") {
      this.hidePopup()
    }
  }

  closeBackground(event) {
    if (event && this.popupTarget.contains(event.target)) {
      return
    }
    this.hidePopup()
  }
}
