import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="custom-number"
export default class extends Controller {
  static targets = ["context"];
  connect() {
    const minus = this.contextTarget.querySelector('.minus-btn');
    const plus = this.contextTarget.querySelector('.plus-btn');
    const input = this.contextTarget.querySelector('input');
    const buttons = this.contextTarget.querySelectorAll('button');
    const value = parseInt(input.value);

    if(parseInt(input.min) === value){
      minus.disabled = true;
    }
    if(parseInt(input.max) === value){
      plus.disabled = true;
    }

    buttons.forEach(btn => {
        btn.addEventListener('click', (e)=>{
          console.log();

          if(btn.classList.contains('plus-btn')){
            let nextValue = (value + 1).toString();
            input.value = nextValue;
          }
          else{          
            let nextValue = (value - 1).toString();
            input.value = nextValue;
          }

          this.dispatch('change');
        })
    });

  }
}
