import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sidebar"
export default class extends Controller {
  static targets = ["sidebar", "btn"]
  connect() {
  }

  toggleSidebar() {
    let menuElts = this.sidebarTarget.querySelectorAll('.sidebar__menu');
    this.sidebarTarget.classList.toggle('active');

    menuElts.forEach(elt => {
      console.log(elt);
      elt.classList.toggle('active');
    });
  }
}
