import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="radio-box"
export default class extends Controller {
  static targets = ["box"];
  connect() {
    const radios = this.boxTarget.querySelectorAll('input');
    const labels = this.boxTarget.querySelectorAll('.row');

    this.boxTarget.addEventListener("click", ()=>{
      if (!Array.from(radios).some((r) => r.checked)) {
        radios[0].checked = true;
        this.dispatch('change');
      }
    })
  }
}
