import panel from "./panel";
import popup from "./popup";
import showif from "./showif"

const linked = {
    selectors: [],
    init() {
        this.selectors.forEach(selector => {
            if(!$(selector).attr('linked-set')) {
                $(selector).on("click", (e) => {
                    if($(e.target).attr('prevent-linked')) {
                        return
                    }

                    let path = $(e.currentTarget).attr("data-path");
                    let append = $(e.currentTarget).attr("data-append");
                    let modules = $(e.currentTarget).attr("data-modules")
                    let scrollable = $(e.currentTarget).attr("data-scrollable")
                    panel.close()
                    popup.close()
    
                    if(path && append) {
                        $.ajax({
                            method: "GET",
                            url: `${window.location.origin}${path}`,
                            success: (res) => {
                                if(append[0] == ".") {
                                    $(append).append(res)
                                } else if(append == "panel") {
                                    panel.init(res)
                                } else if(append =="popup" || append == "popup-md") {
                                    let size = append == "popup-md" ? "md" : "reg"
                                    popup.init(res, ()=>{
                                        if (modules) {
                                            if(modules.includes('showif')) {
                                                showif.init()
                                            }
                                        }
    
                                        if(scrollable) {
                                            $('.popup').addClass('scrollable')
                                        }
                                    }, size)
                                }
                            }
                        })
                    } else if(path) {
                        Turbo.visit(path)
                    }
                })
            }

            $(selector).attr('linked-set', true)
        })
    },

    clearSelectors() {
        this.selectors.forEach(selector => {
            $(selector).removeAttr('linked-set')
        })
    }
}

export default linked;