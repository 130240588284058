import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="search"
export default class extends Controller {
  static targets = ["input", "listElt"]
  connect() {
  }

  search() {
    let fields = [];
    let value = this.inputTarget.value;
    fields = this.listEltTarget.querySelectorAll('.field-card');
    fields.forEach(function(element) {
        let itemName = element.querySelector('.field-name');
        if (itemName.textContent.toLowerCase().includes(value.toLowerCase())) {
            element.classList.remove("hide");
        } else {
            element.classList.add("hide");
        }
    });
  }
}