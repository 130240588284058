const showif = {
    init() {
        $('[showif]').each((i, elm) => {
            showif.apply(elm)
        })
    },

    apply(elm) {
        let [input_id, value] = $(elm).attr('showif').split(',')
        input_id = `#${input_id}`

        showif.toggler(elm, input_id, value)
        
        $(input_id).on('change', () => {
            showif.toggler(elm, input_id, value)
        })
    },

    toggler(elm, input_id, value) {
        // specific for checkboxes
        let input_value = $(input_id).val()
        if($(input_id).attr('type') == 'checkbox') {
            input_value = $(input_id).is(':checked').toString()
        }

        let operator = value[0]
        value = value.substring(1)
        let result = Boolean(input_value == value)
        if(operator == "!") {
            result = !result
        }

        if(result) {
            $(elm).show()
        } else {
            $(elm).hide()
        }

        if($(elm).attr('showif-ads') && $(elm).attr('showif-ads').split(',').includes('clearInputs')) {
            this.clearInputs(elm)
        }
    },

    clearInputs(elm) {
        $(elm).find('input').val('')
    }
}

export default showif