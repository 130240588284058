const subbar = {
    init() {
        // default select
        $(`div[data-container]`).removeClass("active")
        $(".subbar.container span").removeClass("active")

        $(".subbar.container li:first-child span").addClass("active")
        let default_container_id = $(".subbar.container span").attr("data-container-id")
        if(default_container_id != undefined) {
            $(`div#${default_container_id}[data-container]`).addClass("active")
        }

        $(".subbar.container span").on("click", (e) => {
            subbar.desactive()
            let container_id = $(e.currentTarget).attr("data-container-id");
            $(e.currentTarget).addClass("active")
            $(`div#${container_id}[data-container]`).addClass("active")
            // // prepare container to avoid conflicts between forms
            // $(`div[data-container] submit`).on('click', (e) => {
            //     e.preventDefault()
            // })
            // $(`div#${container_id}[data-container] submit`).on('click', (e) => {})
        })
    },

    desactive() {
        $(".subbar.container span").removeClass("active")
        $(`div[data-container]`).removeClass("active")
    }
}

export default subbar
